import React, { FC } from 'react';
import './ScopeOfProject.scss';
import SectionTitle from '../../../components/common/SectionTitle';
import Links from '../../../components/pagesComponents/CaseStudy/Links';
import Span from '../../../components/pagesComponents/CaseStudy/Span';
import WalmartLayout from '../../../components/PagesLayouts/WalmartLayout';
import FixedButton from '../../../components/common/FixedButton';
import { TLinkSp } from '../../../types/types';

const ScopeOfProject: FC = () => {
    const projectManagementLinks: TLinkSp = [
        { id: 0, title: 'Slack' },
        { id: 1, title: 'Jira' },
        { id: 2, title: 'Confluence' },
    ];

    const servicesLinks: TLinkSp = [
        { id: 0, title: 'Research', type: 'span' },
        { id: 1, title: 'Analytics', type: 'span' },
        { id: 2, title: 'Web development', type: 'span' },
        { id: 3, title: 'Cloud Services', type: 'span' },
        { id: 4, title: 'Quality control', margin: 'mb-15', type: 'span' },
        { id: 5, title: 'UX Design', type: 'paragraph' },
        { id: 6, title: 'UI Design', type: 'paragraph' },
    ];

    const backendLinks: TLinkSp = [
        { id: 0, title: 'AWS Web Hosting' },
        { id: 1, title: 'Amazon AWS' },
        { id: 2, title: 'Ruby on Rails' },
        { id: 3, title: 'NoSQL' },
        { id: 4, title: 'DynamoDB' },
        { id: 5, title: 'ECS through CircleCI' },
        { id: 6, title: 'CI/CD - CircleCI' },
    ];

    const frontendLinks: TLinkSp = [{ id: 0, title: 'React' }];

    const otherTechnologiesLinks: TLinkSp = [
        { id: 0, title: 'Microservice architecture' },
        { id: 1, title: 'React v 16.13.1' },
        { id: 2, title: 'Ruby on Rails as API', margin: 'mb-5' },
        { id: 3, title: 'Rails v 5.2' },
        { id: 4, title: 'Ruby 2.5.5', margin: 'mb-5' },
    ];

    const platformsLinks: TLinkSp = [{ id: 0, title: 'Web', margin: 'mb-5', type: 'span' }];

    return (
        <WalmartLayout>
            <div className="scope-of-project__wrapper">
                <SectionTitle type="primary" title="Scope of project" />
                <div className="scope-of-project__body">
                    <Span type="headline" subtype="mb-5">
                        Key results
                    </Span>
                    <Span type="paragraph" subtype="mb-30">
                        Web app was built within 9 months and 7 blackbirders involved via fully
                        managed it services model.
                    </Span>
                    <Span type="headline" subtype="mb-5">
                        Front End
                    </Span>

                    <div className="scope-of-project__technologies scope-of-project__technologies--mb">
                        {frontendLinks.map((front) => (
                            <Span key={front.id} type="span" subtype="mr-15">
                                {front.title}
                            </Span>
                        ))}
                    </div>
                    <Span type="headline" subtype="mb-5">
                        Back End
                    </Span>
                    <div className="scope-of-project__technologies scope-of-project__technologies--mb">
                        {backendLinks.map((back) => (
                            <Span key={back.id} type="span" subtype="mr-15">
                                {back.title}
                            </Span>
                        ))}
                    </div>
                    <div className="scope-of-project__description">
                        <div className="scope-of-project__item">
                            <Span type="headline" subtype="mb-5">
                                Platforms
                            </Span>
                            {platformsLinks.map((span) => (
                                <Span key={span.id} type={span.type} subtype={span.margin}>
                                    {span.title}
                                </Span>
                            ))}

                            <Span type="headline" subtype="mb-5">
                                Services and expertise
                            </Span>
                            {servicesLinks.map((span) => (
                                <Span key={span.id} type={span.type} subtype={span.margin}>
                                    {span.title}
                                </Span>
                            ))}
                        </div>
                        <div className="scope-of-project__item">
                            <Span type="headline" subtype="mb-5">
                                Other technologies
                            </Span>
                            {otherTechnologiesLinks.map((tech) => (
                                <Span key={tech.id} type="paragraph" subtype={tech.margin}>
                                    {tech.title}
                                </Span>
                            ))}
                        </div>
                        <div className="scope-of-project__item">
                            <Span type="headline" subtype="mb-5">
                                Cooperation model
                            </Span>
                            <Span type="span" subtype="mb-15">
                                Fully managed IT services
                            </Span>
                            <Span type="headline" subtype="mb-5">
                                Project management
                            </Span>
                            {projectManagementLinks.map((span) => (
                                <Span key={span.id} type="paragraph">
                                    {span.title}
                                </Span>
                            ))}
                        </div>
                    </div>
                    <Links />
                </div>
            </div>
            <FixedButton/>
        </WalmartLayout>
    );
};

export default ScopeOfProject;
